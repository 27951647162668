import "./mlpushmenu.js";

(function ($) {

  $(function () {
    var pushMenu = new mlPushMenu(
      $('.sb-menu')[0],
      $('.sb-menu--trigger')[0],
      {
        wrapper: $('.site-container')[0]
      }
    );

    $('.close-menu').click(function() {
      pushMenu.open = false;
    });

    if(!$('body').hasClass('mobile-menu-opened')) {
      $('.sb-search--trigger').on('click', function(e) {
        e.preventDefault();
        $('.search--wrap').toggleClass('active');
      });
    }

  });

})(jQuery);
