jQuery(function ($) {

  $('.events-slider').slick({
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerPadding: 0,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          infinite: true,
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          arrows: false,
          dots: true
        }
      },
    ]
  });
	
});
