jQuery(function ($) {

	var menu = $("#menu-main");
	var menuItemsWithKids = menu.find(
		'> .menu-item-has-children, > .menu-item-has-children .sub-menu > .menu-item-has-children'
	);

	menuItemsWithKids.hover(function() {
		$(this).toggleClass('sfHover');
	});
	
});
