import lozad from "./lozad.js";

const observer = lozad(".lozad", {
  load: function(el) {
    el.onload = function() {
      el.classList.add("fade");
    };
  },
  loaded: function(el) {
    el.classList.add("fade");
  },
});

observer.observe();

jQuery(function($) {
  // Remove the 'no-js' <body> class
  $("html").removeClass("no-js");

  $('iframe[src*="youtube.com"]').each(function() {
    let el = $(this);
    el.wrap("<div class='responsive-video'></div>");
  });
});

var homeBanners = document.querySelectorAll(".home-banner-js");

if (homeBanners.length > 0) {
  function setBannerBackgroundImages(banners, device) {
    var setHomeBanners = banners;

    for (var i = 0; i < setHomeBanners.length; i++) {
      var currentBanner = setHomeBanners[i];
      var currentBannerDesktopImage = currentBanner.getAttribute(
        "data-desktop-img"
      );
      var currentBannerMobileImage = currentBanner.getAttribute(
        "data-mobile-img"
      );

      if (device == "mobile") {
        setHomeBanners[i].style.backgroundImage =
          "url('" + currentBannerMobileImage + "')";
      } else {
        setHomeBanners[i].style.backgroundImage =
          "url('" + currentBannerDesktopImage + "')";
      }
    }
  }

  setBannerBackgroundImages(homeBanners, "desktop");

  var callback = function() {
    var limitFunc = function() {
      if (window.innerWidth < 768) {
        setBannerBackgroundImages(homeBanners, "mobile");
      } else {
        setBannerBackgroundImages(homeBanners, "desktop");
      }
    };

    window.addEventListener("resize", limitFunc);
    window.addEventListener("load", limitFunc);
  };
}

if (
  document.readyState === "complete" ||
  (document.readyState !== "loading" && !document.documentElement.doScroll)
) {
  callback();
} else {
  document.addEventListener("DOMContentLoaded", callback);
}


