jQuery(function ($) {

  $('.tabs li input').on('click', function(e) {

    $('.tabs li').removeClass('active');
    $('.performance-content .tab-content').removeClass('active');

    // Add active to li for clicked tab
    $(this).closest('li').addClass('active');

    // Show clicked tab content
    let tab = $(this).siblings('.tab-label').attr('data-tab');
    // let tabContent = $('.performance-content .tab-content').attr('data-tab-content', tab);
    let content = $(".tab-content[data-tab-content='" + tab +"']");
    content.addClass('active');
    //console.log('tabContent#: ', content);
  });
	
});
