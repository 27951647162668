(function($) {
    'use strict';

    $('.js-sb-modal').each(function(i, e) {
        var $root = $(e),
            $state = $root.find('.js-state'),
            bodyClass = $root.data('bodyClass');

        var url_change = function(oldURL, newURL) {
            if (/#modal=(.*)$/.test(newURL)) {
                var id = /#edit=(.*)/.exec(newURL)[1];
                var $base = $('.' + id);
                $base.removeClass('mode-display').addClass('mode-edit');
            }
        };

        $state.on("change", function() {
            if ($(this).is(":checked")) {
                $("body").addClass(bodyClass + "-open");
                $root.trigger('modal:show');
            } else {
                $("body").removeClass(bodyClass + "-open");
                $root.trigger('modal:hide');
            }
        });

        $root.find(".js-fade-screen, .js-close").on("click", function() {
            $state.prop("checked", false).change();
        });
        $root.find(".js-inner").on("click", function(e) {
            e.stopPropagation();
        });

        $root.data('sb-modal', {
           show: function(state) {
               $state.prop('checked', state);
           }
        });
    });
})(jQuery);